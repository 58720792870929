import React, { useEffect, useState } from "react";
import "./Footer.css";
import {
  ChevronDown,
  Facebook,
  Instagram,
  Linkedin,
  TwitterX,
} from "react-bootstrap-icons";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useGetParentCategoryInfoQuery } from "../../../RTK/Api/CategoryApi";
import { useGetContactInfoQuery } from "../../../RTK/Api/ContactApi";
import { toast } from "react-toastify";
import { useGetCmsPagesQuery } from "../../../RTK/Api/CMSApi";
import TransHook from "./../../../hooks/translation/trans-hook";
import { formatPhoneNumber } from "../../../hooks/util/Helpers";
import Loader from "../../Loaders/Loader";

const Footer = () => {
  const navigate = useNavigate();

  const { t } = TransHook();
  const lang = localStorage.getItem("ecomLang");
  const screen = localStorage.getItem("screen") || "large";

  const {
    data: CMSData,
    error: cmsError,
    isLoading: isCmsLoading,
  } = useGetCmsPagesQuery();
  if (cmsError) toast.error(cmsError);

  const [ParentCategories, setParentCategories] = useState([]);
  const {
    data: category,
    error: categoryError,
    isLoading: isCategoryLoading,
  } = useGetParentCategoryInfoQuery();
  if (categoryError) toast.error(categoryError);
  useEffect(() => {
    setParentCategories(
      category?.data?.filter(
        (category) =>
          !category.parentCategory &&
          category.ecommerceVisible &&
          category.ecommerceHomeVisible
      )
    );
  }, [category]);

  const {
    data: contact,
    error: contactError,
    isLoading: isContactLoading,
  } = useGetContactInfoQuery();
  if (contactError) console.error(contactError);

  const emptyData = {
    placeholder: true,
    companyId: "660fab2cb74becb936dee560",
    companyEmail: "abdulrahman.judy0604@gmail.com",
    companyLogo:
      "https://nooncar.com:8003/companyinfo/company-9e4c6278-8994-462a-b49b-02245c748224-1717483800360.png",
    companyName: "kai",
    companyTax: "6081018629",
    companyTel: "05300000000",
    companyAddress:
      "36017 NOLU SK SAIT SAYIN IS MERKEZI No:NO: 30 27090 SEHITKAMIL/ Gaziantep GAZİKENT, Gaziantep",
    companyColors: [],
    facebookUrl: "profile.php?id=100088659776655",
    instagramUrl: "smartinb_solutions/",
    xtwitterUrl: "SmartinbX",
    linkedinUrl: "https://www.linkedin.com/company/smartinb-solutions",
  };

  let comData = Cookies.get("CompanyData")
    ? JSON.parse(Cookies.get("CompanyData"))
    : emptyData;

  const handleButtonClick = (id) => {
    navigate(`/products/${id}/category`);
  };

  if (isCmsLoading || isCategoryLoading || isContactLoading) {
    return <Loader />;
  } else {
    return (
      <div
        className={`footer ${screen === "large" ? "" : "text-center"}  ${
          lang === "ar" ? "arabic-text" : ""
        }`}
      >
        <div className="footer-middle container px-0 py-5">
          <div className="row px-5">
            {/* Company logo */}
            <div className="col-lg-2 col-md-6 m-auto">
              <Link to="/">
                <img
                  src={comData?.companyLogo}
                  alt={comData?.companyName}
                  className="logo m-0 p-0 w-100 mx-auto"
                />
              </Link>
            </div>

            {/* Contact info & Social media */}
            <div className="col-lg-3 col-md-6">
              <div
                className={`row m-0 ${
                  screen === "large" ? "" : "justify-content-center"
                }`}
              >
                <div className="contact-widget phone">
                  <h4 className="widget-title">{t("callUsNow")}:</h4>
                  <Link to={`https://wa.me/${contact?.data?.phone}`}>
                    {formatPhoneNumber(contact?.data?.phone)}
                  </Link>
                </div>
              </div>
              <div
                className={`row m-0 ${
                  screen === "large" ? "" : "justify-content-center"
                }`}
              >
                <div className="contact-widget email">
                  <h4 className="widget-title">{t("email")}:</h4>
                  <Link to={`mailto:${contact?.data?.email}`}>
                    {contact?.data?.email}
                  </Link>
                </div>
              </div>
              <div
                className={`row m-0 ${
                  screen === "large" ? "" : "justify-content-center"
                }`}
              >
                <div className="contact-widget follow">
                  <h4 className="widget-title ls-n-10">{t("followUs")}</h4>
                  <div className="social-icons">
                    {contact?.data?.instagramUrl !== "" && (
                      <Link
                        to={`https://instagram.com/${contact?.data?.instagramUrl}`}
                        className="social-icon social-instagram"
                        title="Instagram"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Instagram color="#379397" />
                      </Link>
                    )}
                    {contact?.data?.facebookUrl !== "" && (
                      <Link
                        to={`https://facebook.com/${contact?.data?.facebookUrl}`}
                        className="social-icon social-facebook"
                        title="Facebook"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Facebook color="#379397" />
                      </Link>
                    )}
                    {contact?.data?.xtwitterUrl !== "" && (
                      <Link
                        to={`https://x.com/${contact?.data?.xtwitterUrl}`}
                        title="X (Twitter)"
                        className="social-icon social-twitter"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <TwitterX color="#379397" />
                      </Link>
                    )}
                    {contact?.data?.linkedinUrl !== "" && (
                      <Link
                        to={contact?.data?.linkedinUrl}
                        className="social-icon social-linkedin fab fa-linkedin-in"
                        target="_blank"
                        title="LinkedIn"
                        rel="noreferrer"
                      >
                        <Linkedin color="#379397" />
                      </Link>
                    )}
                  </div>
                  {/* <!-- End .social-icons --> */}
                </div>
              </div>
            </div>

            {screen === "large" ? (
              <>
                {/* Categories */}
                <div className="col-lg-2 col-md-6">
                  <div className="widget">
                    <h4 className="widget-title">{t("categories")}</h4>

                    <ul className="links">
                      {ParentCategories?.slice(0, 5).map((cat, i) => {
                        return (
                          <li key={i}>
                            <span
                              onClick={() => handleButtonClick(cat._id)}
                              style={{ color: "#777", cursor: "pointer" }}
                            >
                              {lang == "ar"
                                ? cat.nameAR
                                : lang == "tr"
                                ? cat.nameTR
                                : cat.name}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>

                {/* About */}
                <div className="col-lg-2 col-md-6">
                  <div className="widget">
                    <h4 className="widget-title">{t("about")}</h4>

                    <ul className="links">
                      {CMSData?.data?.page &&
                        CMSData?.data?.page?.map((item, i) => {
                          return (
                            <li key={i}>
                              <span
                                style={{ cursor: "pointer", color: "#777" }}
                                onClick={() => navigate(`/CMS/${item._id}`)}
                              >
                                {item.name}
                              </span>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>

                {/* Customer care */}
                <div className="col-lg-2 col-md-6">
                  <div className="widget">
                    <h4 className="widget-title">{t("customerCare")}</h4>
                    <ul className="links">
                      <li>
                        <Link to="/account-details">{t("myAccount")}</Link>
                      </li>
                      {/* <li>
                      <Link to="#">{t("termsConditions")}</Link>
                    </li>
                    <li>
                      <Link to="#">{t("faq")}</Link>
                    </li> */}
                    </ul>
                  </div>
                </div>
              </>
            ) : (
              <>
                {/* Categories */}
                <div
                  className="accordion col-lg-2 col-md-6 mb-4"
                  id="accordionCategory"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <div
                        className="accordion-button d-flex justify-content-between cursor-pointer"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseCategory"
                        aria-expanded="true"
                        aria-controls="collapseCategory"
                      >
                        {t("categories")}
                        <ChevronDown />
                      </div>
                    </h2>
                    <div
                      id="collapseCategory"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionCategory"
                    >
                      <div className="accordion-body">
                        {ParentCategories?.slice(0, 5).map((cat, i) => {
                          return (
                            <p
                              key={i}
                              onClick={() => handleButtonClick(cat._id)}
                              style={{ color: "#777", cursor: "pointer" }}
                            >
                              {cat.name}
                            </p>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>

                {/* About */}
                <div
                  className="accordion col-lg-2 col-md-6 mb-4"
                  id="accordionAbout"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <div
                        className="accordion-button d-flex justify-content-between cursor-pointer"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseAbout"
                        aria-expanded="true"
                        aria-controls="collapseAbout"
                      >
                        {t("about")}
                        <ChevronDown />
                      </div>
                    </h2>
                    <div
                      id="collapseAbout"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionAbout"
                    >
                      <div className="accordion-body">
                        {CMSData?.data &&
                          CMSData?.data?.page?.map((item, i) => {
                            return (
                              <p
                                key={i}
                                style={{ cursor: "pointer", color: "#777" }}
                                onClick={() => navigate(`/CMS/${item._id}`)}
                              >
                                {item.name}
                              </p>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Customer care */}
                <div
                  className="accordion col-lg-2 col-md-6 mb-4"
                  id="accordionCustomerCare"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <div
                        className="accordion-button d-flex justify-content-between cursor-pointer"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseCustomerCare"
                        aria-expanded="true"
                        aria-controls="collapseCustomerCare"
                      >
                        {t("CustomerCare")}
                        <ChevronDown />
                      </div>
                    </h2>
                    <div
                      id="collapseCustomerCare"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionCustomerCare"
                    >
                      <div className="accordion-body">
                        <p onClick={() => navigate("/account-details")}>
                          {t("myAccount")}
                        </p>
                        <p onClick={() => navigate("#")}>
                          {t("termsConditions")}
                        </p>
                        <p onClick={() => navigate("#")}>{t("faq")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        {/* Smartinb credits */}
        <div className="footer-bottom d-sm-flex align-items-center">
          <div className="footer-left">
            <span className="footer-copyright">
              ©{" "}
              <Link to="https://smartinb.com" target="_blank">
                <b>Smartinb</b>
              </Link>{" "}
              2025. {t("allRightsReserved")}
            </span>
          </div>
        </div>
      </div>
    );
  }
};

export default Footer;
