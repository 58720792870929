import React, { useCallback, useState } from "react";
import ProductCard from "./ProductCard";
import { toast } from "react-toastify";
import TransHook from "../../../hooks/translation/trans-hook";
import { XSquareFill } from "react-bootstrap-icons";
import "../../../pages/Product/Products.css";
import { Button, Modal } from "react-bootstrap";
import SideNav from "../../utility/SideNav/SideNav";

const ProductsContainer = ({
  productList,
  error,
  handleSortChange,
  brands,
  setBrands,
  priceRange,
  setPriceRange,
  ratingRange,
  setRatingRange,
  hasMoreProducts,
  handleLoadMoreClick,
  categories,
  brandsList,
  setBrandId,
  setMaxPrice,
  setMinPrice,
  setMaxAvg,
  setMinAvg,
  selectedBrands,
  setSelectedBrands,
  setSelectedBrandNames,
}) => {
  const { t } = TransHook();
  const lang = localStorage.getItem("ecomLang");

  const handleSort = useCallback(
    (e) => {
      handleSortChange(e.target.value);
    },
    [handleSortChange]
  );

  if (error) toast.error(error);

  const screen = localStorage.getItem("screen") || "large";

  // State for Modal
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <div
      className={`col-lg-9 main-content ${lang === "ar" ? "arabic-text" : ""}`}
      style={{ minHeight: "840px" }}
    >
      {/* Filters Modal for small screens */}
      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t("filters")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SideNav
            categories={categories}
            brands={brandsList}
            products={productList}
            setBrandsArray={setBrandId}
            setMaxPrice={setMaxPrice}
            setMinPrice={setMinPrice}
            setMaxAvg={setMaxAvg}
            setMinAvg={setMinAvg}
            selectedBrands={selectedBrands}
            setSelectedBrands={setSelectedBrands}
            setSelectedBrandNames={setSelectedBrandNames}
            selectedPriceRange={priceRange}
            setSelectedPriceRange={setPriceRange}
            ratingRange={ratingRange}
            setRatingRange={setRatingRange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            {t("close")}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Sorting Toolbar */}
      <nav
        className="toolbox sticky-header d-flex"
        style={{ width: "100%", marginBottom: "30px" }}
        data-sticky-options="{'mobile': true}"
      >
        <div className="SortBy">
          <label>{t("sortBy")}:</label>
          <select name="orderby" className="form-control" onChange={handleSort}>
            <option value="default">{t("defaultSorting")}</option>
            <option value="rating">{t("highestRated")}</option>
            <option value="date">{t("newest")}</option>
            <option value="price">{t("priceLowHigh")}</option>
            <option value="price-desc">{t("priceHighLow")}</option>
          </select>
        </div>
        {/* Button to open filters modal on small screens */}
        {screen !== "large" && (
          <button onClick={handleOpenModal} className="btn filters-button">
            {t("filters")}
          </button>
        )}
      </nav>

      {/* Selected Filters Display */}
      <div className="Filters_list">
        {brands?.length > 0 || priceRange.from || ratingRange.rateMin ? (
          <ul>
            {/* Display selected brand names */}
            {brands?.length > 0 &&
              brands.map((brand, index) => <li key={index}>{brand}</li>)}

            {/* Display selected price range */}
            {priceRange?.from && (
              <li>
                {priceRange.from.toFixed(2)} - {priceRange.to.toFixed(2)}{" "}
                <XSquareFill
                  onClick={() => {
                    setPriceRange({});
                    setMinPrice(null);
                    setMaxPrice(null);
                  }}
                  style={{ cursor: "pointer" }}
                />
              </li>
            )}
            {ratingRange.rateMin && (
              <li>
                {ratingRange.rateMin} - {ratingRange.rateMax} {t("stars")}{" "}
                <XSquareFill
                  onClick={() => {
                    setRatingRange({});
                    setMinAvg(null);
                    setMaxAvg(null);
                  }}
                  style={{ cursor: "pointer" }}
                />
              </li>
            )}
          </ul>
        ) : null}
      </div>

      {/* Products Grid */}
      <div className="row search-products">
        {productList?.map((product, index) => (
          <div
            key={index}
            className={`${screen === "large" ? "col-4" : "col-6"}`}
            id={product?._id}
          >
            <ProductCard product={product} />
          </div>
        ))}
      </div>

      {/* Load More Button */}
      {hasMoreProducts && (
        <button className="LoadProductsButton" onClick={handleLoadMoreClick}>
          {t("loadMoreProducts")}
        </button>
      )}
    </div>
  );
};

export default ProductsContainer;
