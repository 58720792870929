import { useEffect, useState } from "react";
import AddressesHook from "../profile/AddressesHook";
import { useAddOrderItemMutation } from "../../RTK/Api/OrderApi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import TransHook from "./../translation/trans-hook";
import GetShippingPricesHook from "../ShippingPrice/GetShippingPriceHook";
import GetPaymentMethodsHook from "../PaymentMethods/GetPaymentMethodsHook";
import { useGetCmsPagesQuery } from "../../RTK/Api/CMSApi";

const CheckoutHook = (cartId) => {
  const navigate = useNavigate();
  const { t } = TransHook();
  const [activeShipIndex, setActiveShipIndex] = useState(null);
  const [activeBilIndex, setActiveBilIndex] = useState(null);
  const [userInfo, setUserInfo] = useState({});
  const [signedIn] = useState(!!Cookies.get("userData"));

  const {
    addressGet,
    handleAliasChange,
    handleFullNameChange,
    handleCityChange,
    handleDetailsChange,
    handleTownChange,
    handlePhoneChange,
    districts,
    alias,
    fullName,
    city,
    details,
    town,
    phone,
    saveAddress,
    clearFields,
  } = AddressesHook();

  const [addingAddress, setAddingAddress] = useState(false);
  const [differentBilling, setDifferentBilling] = useState(false);

  const cancel = () => {
    setAddingAddress(!addingAddress);
    clearFields();
  };

  const save = () => {
    saveAddress();
    setAddingAddress(!addingAddress);
    clearFields();
  };

  const [userData, setUserData] = useState(null);

  const [cart, setCart] = useState();
  useEffect(() => {
    if (signedIn) {
      const userDataString = Cookies.get("userData");
      const userData = JSON.parse(userDataString);
      setUserInfo(userData);
    }

    const data = Cookies.get("userData");
    if (data) {
      setUserData(JSON.parse(data));
    }
    window.scrollTo(0, 0);

    const cartJson = JSON.parse(localStorage.getItem("cart"));

    if (cartJson) setCart(cartJson);
  }, []);

  const activeCart = cart;

  const selectShipAddress = (i, address) => {
    setActiveShipIndex(i);
    localStorage.setItem("shipAddress", JSON.stringify(address));
    if (!differentBilling) {
      localStorage.setItem("billAddress", JSON.stringify(address));
    }
  };

  const selectBilAddress = (i, address) => {
    setActiveBilIndex(i);
    localStorage.setItem("billAddress", JSON.stringify(address));
  };

  const [addOrderItem, { isLoading: isLoadingCheckout }] =
    useAddOrderItemMutation();

  const createFullAddress = (address) => {
    const { name, ...rest } = address;
    return { ...rest, fullName: name };
  };

  const cur = JSON.parse(Cookies.get("CompanyCurrency"));
  const lang = localStorage.getItem("ecomLang");
  const [bankInfo, setBankInfo] = useState({});
  const [showTerms, setShowTerms] = useState(false);

  const { paymentMethods, isLoading } = GetPaymentMethodsHook();
  const { shippingPrices } = GetShippingPricesHook();

  const [paymentMethod, setPaymentMethod] = useState("onlinePayment");
  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const selectedPaymentMethod = paymentMethods?.find(
    (item) => item.name === paymentMethod
  );

  const calculateShippingCost = (cartItems) => {
    let totalWeight = 0;
    let totalVolumetricWeight = 0;

    cartItems.forEach((item) => {
      const { width, length, height, weight } = item.product;
      const volumetricWeight = (length * width * height) / 5000;
      const effectiveWeight = volumetricWeight * item.quantity;
      totalWeight += weight * item.quantity;
      totalVolumetricWeight += effectiveWeight;
    });

    const effectiveWeight = Math.max(totalWeight, totalVolumetricWeight);

    if (!shippingPrices || !shippingPrices[0]?.prices) {
      console.error("Shipping prices data is missing or malformed.");
      return null;
    }

    const shippingPrice = shippingPrices[0]?.prices?.find(
      (price) =>
        effectiveWeight >= price.fromWeight && effectiveWeight <= price.toWeight
    );

    return shippingPrice ? shippingPrice.price : null;
  };

  const [additionRate, setAdditionRate] = useState(0);
  useEffect(() => {
    if (!paymentMethods?.length) return;

    if (paymentMethods && paymentMethods.length > 0) {
      const firstAvailableMethod = paymentMethods.find(
        (method) => method.status
      );
      if (firstAvailableMethod) {
        setPaymentMethod(firstAvailableMethod.name);
      }
    }

    const onlinePaymentMethod = paymentMethods.find(
      (method) => method.name === "onlinePayment"
    );

    if (onlinePaymentMethod) {
      const { extraCharge = 0, companyRatio = 0 } = onlinePaymentMethod;
      const companyDeduction = (extraCharge * companyRatio) / 100;
      setAdditionRate(extraCharge - companyDeduction);
    } else {
      setAdditionRate(0); // Reset in case onlinePayment is removed
    }

    const transferPaymentMethod = paymentMethods.find(
      (method) => method.name === "bankTransfer"
    );

    setBankInfo(transferPaymentMethod || null); // Reset if not found
  }, [paymentMethods]);

  const [totalProductCost, setTotalProductCost] = useState();
  const calculateTotalCartPrice = (methodName) => {
    let productCost = 0; // Moved inside to reset every call

    // Calculate total product cost
    activeCart?.cartItems?.forEach((item) => {
      productCost +=
        item?.product?.ecommercePriceMainCurrency > 0
          ? item?.product?.ecommercePriceMainCurrency * item.quantity
          : item.taxPrice * item.quantity;
    });

    // Calculate shipping cost
    const totalShippingCost =
      calculateShippingCost(activeCart?.cartItems || []) || 0;

    // Get the selected payment method
    const selectedMethod = paymentMethods?.find(
      (method) => method.name === methodName
    );
    const extraCharge = selectedMethod?.extraCharge || 0;
    const companyRatio = selectedMethod?.companyRatio || 0;

    // Calculate the adjusted addition rate
    const companyDeduction = (extraCharge * companyRatio) / 100;
    const adjustedRate = extraCharge - companyDeduction;

    // Calculate total cost
    const baseTotalCost = productCost + totalShippingCost;
    const additionAmount = (baseTotalCost * adjustedRate) / 100;
    let grandTotal = baseTotalCost + additionAmount;

    // Apply exchange rate if needed
    const exchangeRate = cur?.exchangeRate || 1; // Fetch exchange rate
    grandTotal = grandTotal * exchangeRate; // Apply exchange rate

    return { totalShippingCost, grandTotal, productCost };
  };
  const { totalShippingCost, grandTotal, productCost } =
    calculateTotalCartPrice(paymentMethod);

  useEffect(() => {
    setTotalProductCost(productCost);
  }, [productCost]);

  // Calculate savings compared to the default method
  const calculateSavings = () => {
    const defaultMethod = "onlinePayment";
    if (paymentMethod === defaultMethod) return 0;

    const defaultTotal = calculateTotalCartPrice(defaultMethod).grandTotal;
    return (defaultTotal - grandTotal).toFixed(2);
  };

  const savings = calculateSavings();

  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [terms, setTerms] = useState();
  const { data: CMSData, error: cmsError } = useGetCmsPagesQuery();
  if (cmsError) toast.error(cmsError);
  useEffect(() => {
    if (CMSData?.data) {
      setTerms(CMSData.data?.page?.filter((page) => page.key === "TermsConds"));
    }
  }, [CMSData]);

  const screen = localStorage.getItem("screen") || "large";

  const createOrder = async (method) => {
    try {
      const shippingAddress = createFullAddress(
        JSON.parse(
          localStorage.getItem("shipAddress")
            ? localStorage.getItem("shipAddress")
            : localStorage.getItem("savedShippingAddress")
        )
      );
      const billingAddress = createFullAddress(
        JSON.parse(
          localStorage.getItem("billAddress")
            ? localStorage.getItem("billAddress")
            : localStorage.getItem("savedBillingAddress")
        )
      );

      const ipAddress = await getIP();

      const currency = JSON.parse(Cookies.get("CompanyCurrency"));
      const body = {
        shippingAddress,
        billingAddress,
        ipAddress,
        userInfo,
        totalShippingCost: totalShippingCost / currency.exchangeRate,
      };

      const paymentContext = await addOrderItem({ id: cartId, body }).unwrap();
      if (method === "onlinePayment") {
        localStorage.setItem("order", JSON.stringify(paymentContext));
        navigate("/payment");
      } else {
        navigate("/order-complete", { state: { order: paymentContext } });
        //localStorage.removeItem("userCart");
        localStorage.removeItem("cart");
        localStorage.removeItem("billAddress");
        localStorage.removeItem("shipAddress");
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message || t("errorOccurred"));
    }
  };

  const getIP = async () => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error("Error fetching IP address:", error);
      return null;
    }
  };

  return {
    addressGet,
    activeShipIndex,
    activeBilIndex,
    addingAddress,
    alias,
    fullName,
    city,
    details,
    town,
    phone,
    activeCart,
    differentBilling,
    isLoadingCheckout,
    userData,
    cur,
    lang,
    bankInfo,
    showTerms,
    isLoading,
    selectedPaymentMethod,
    totalShippingCost,
    savings,
    acceptedTerms,
    terms,
    screen,
    additionRate,
    grandTotal,
    paymentMethods,
    paymentMethod,
    districts,
    totalProductCost,
    setPaymentMethod,
    setAcceptedTerms,
    calculateShippingCost,
    handlePaymentMethodChange,
    setShowTerms,
    handleAliasChange,
    handleFullNameChange,
    handleCityChange,
    handleDetailsChange,
    handleTownChange,
    handlePhoneChange,
    save,
    cancel,
    createOrder,
    setDifferentBilling,
    setAddingAddress,
    selectShipAddress,
    selectBilAddress,
  };
};

export default CheckoutHook;
