import React, { useState } from "react";
import ReviewHook from "../Review/ReviewHook";
import { usePatchOrderMutation } from "../../RTK/Api/OrderApi";
import { toast } from "react-toastify";

const OrderDetailsHook = (order) => {
  const {
    rating,
    review,
    show,
    prodName,
    setRating,
    handleClose,
    handleReviewChange,
    handleShow,
    handleSubmit,
  } = ReviewHook();
  const [patchOrder, { error: patchError }] = usePatchOrderMutation();
  if (patchError) toast.error(patchError);

  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState(false);
  const [currentId, setCurrentId] = useState(null);

  const handleCloseConfirm = () => setShowConfirm(false);

  const handleShowConfirm = (id, reqStatus) => {
    setConfirmStatus(reqStatus);
    setCurrentId(id);
    setShowConfirm(true);
  };

  const update = (orderStatus, id) => {
    const body = [
      {
        _id: id,
        orderStatus,
      },
    ];
    patchOrder({ itemId: order?._id, body })
      .unwrap()
      .then(() => toast.success(`Request to be ${orderStatus} sent`))
      .catch((error) => toast.error(error));
  };

  const handleRequest = () => {
    update(confirmStatus, currentId);
    handleCloseConfirm();
  };

  const statusOrder = [
    "requested",
    "approved",
    "processed",
    "shipped",
    "cancelled",
    "returned",
    "not delivered",
    "delivered",
  ];

  const getStatusClass = (currentStatus, stepStatus) => {
    if (["cancelled", "returned", "not delivered"].includes(stepStatus)) {
      return stepStatus === currentStatus ? "bg-error" : "bg-not-complete";
    }
    const currentIndex = statusOrder.indexOf(currentStatus);
    const stepIndex = statusOrder.indexOf(stepStatus);
    return stepIndex <= currentIndex ? "bg-complete" : "bg-not-complete";
  };

  return {
    rating,
    review,
    show,
    prodName,
    statusOrder,
    showConfirm,
    confirmStatus,
    setRating,
    handleClose,
    handleReviewChange,
    handleShow,
    handleSubmit,
    getStatusClass,
    handleShowConfirm,
    handleCloseConfirm,
    handleRequest,
  };
};

export default OrderDetailsHook;
