import React, { useEffect, useState } from "react";
import LoginImg from "../../assets/images/samsung-s23-ultra-price-flipkart-mobile-sale-offers1704467062235.jpg";
import AuthHook from "../../hooks/Auth/AuthHook";
import { useResetPasswordMutation } from "../../RTK/Api/AuthApi";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import TransHook from "./../../hooks/translation/trans-hook";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [newPassword, SetnewPassword] = useState("");
  const { companyLogo } = AuthHook();
  const [ResetPassword, { data, isSuccess, isError, error }] =
    useResetPasswordMutation();
  const updataPassword = () => {
    ResetPassword({ newPassword, token });
  };
  useEffect(() => {
    if (isSuccess) {
      navigate("/login");
    }
  });

  const { t } = TransHook();
  const lang = localStorage.getItem("ecomLang");

  return (
    <div className={`AuthContainer ${lang === "ar" ? "arabic-text" : ""}`}>
      <div className="Auth d-flex row">
        <div className="col-6 AuthImg">
          <img src={LoginImg} alt="LoginImg" />
        </div>
        <div className="col-6 AuthForm">
          <img src={companyLogo} alt="Logo" />
          <div className="SignInForm">
            <h2>{t("resetPassword")}</h2>
            <p>{t("enterNewPassword")}</p>
            <input
              type="password"
              className="form-control"
              id="exampleInputPassword1"
              onChange={(e) => SetnewPassword(e.target.value)}
              value={newPassword}
              name="password"
              placeholder={t("newPassword")}
            />
            <button
              style={{ width: "50%" }}
              type="submit"
              className="btn btn-primary"
              onClick={() => updataPassword()}
            >
              {t("resetPassword")}
            </button>

            {isSuccess && (
              <div className="sucess-message">
                {data?.message || t("passwordUpdateSuccess")}
              </div>
            )}
            {isError && (
              <div className="error-message">
                {error?.data?.message || t("errorOccurred")}
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer autoClose={2000} />
    </div>
  );
};

export default ResetPassword;
