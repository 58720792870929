import React, { useEffect, useState } from "react";
import LoginImg from "../../assets/images/afaqs_2022-09_3e27442e-a2a2-4b93-913c-a2ff85046fb2_Untitled.webp";
import "./Auth.css";
import AuthHook from "../../hooks/Auth/AuthHook";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import TransHook from "./../../hooks/translation/trans-hook";
import { GoogleOAuthProvider } from "@react-oauth/google";
import GoogleLoginButton from "./GoogleLogin";
import FaccbookLoginButton from "./FaccbookLoginButton";
import { EyeFill, EyeSlashFill } from "react-bootstrap-icons";
import { Helmet } from "react-helmet";

function LoginForm() {
  const {
    handleLogIn,
    handleLogInChange,
    handleSignUp,
    handleSignUpChange,
    logInIsLoading,
    logInError,
    signUpIsLoading,
    signUpError,
    companyLogo,
    googleAuth,
    facebookAuth,
    facebookAppId,
    googleClientId,
  } = AuthHook();

  const [isFlipped, setIsFlipped] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleSignUpClick = () => {
    setIsFlipped(!isFlipped);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") handleLogIn();
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleLogIn]);
  const { t } = TransHook();
  const navigate = useNavigate();
  const lang = localStorage.getItem("ecomLang");
  const isRTL = lang === "ar";
  const toggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <div className={`AuthContainer ${lang === "ar" ? "arabic-text" : ""}`}>
      <Helmet>
        <title>{t("signIn")} - Smart E-commerce</title>
      </Helmet>
      <div className="Auth d-flex row ">
        <div
          className={`col-6 AuthImg ${
            isRTL ? (isFlipped ? "flip-Form" : "") : isFlipped ? "flip-Img" : ""
          }`}
        >
          <img src={LoginImg} alt="LoginImg" />
        </div>
        <div
          className={`col-6 AuthForm ${
            isRTL ? (isFlipped ? "flip-Img" : "") : isFlipped ? "flip-Form" : ""
          }`}
        >
          <img
            src={companyLogo}
            alt="Logo"
            className="Logo cursor-pointer"
            onClick={() => navigate("/")}
          />
          {!isFlipped ? (
            <form className="SignInForm" onSubmit={(e) => e.preventDefault()}>
              <div className="Sign_header">
                <h1>{t("signIn")}</h1>
                <h1
                  className="cursor-pointer text-primary"
                  onClick={handleSignUpClick}
                >
                  {t("signUp")}
                </h1>
              </div>
              <div className="password_con d-flex my-4">
                <input
                  type="email"
                  className="form-control"
                  aria-describedby="emailHelp"
                  onChange={handleLogInChange}
                  name="email"
                  placeholder={t("email")}
                />
                <button className="col-3"></button>
              </div>

              <div className="password_con d-flex">
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control col-9"
                  onChange={handleLogInChange}
                  name="password"
                  placeholder={t("password")}
                />
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    toggleShowPassword();
                  }}
                  className="col-3 p-0"
                >
                  {showPassword ? <EyeSlashFill /> : <EyeFill />}
                </button>
              </div>

              {logInError && (
                <div className="error-message">{t("incorrectCredits")}</div>
              )}
              <div className="Login_buttons">
                <button
                  type="submit"
                  className="confirm_signup col-6"
                  onClick={(e) => {
                    handleLogIn();
                    e.preventDefault();
                  }}
                >
                  {logInIsLoading ? t("signingIn") : t("signIn")}
                </button>
                {googleAuth && (
                  <GoogleOAuthProvider clientId={googleClientId}>
                    <GoogleLoginButton />
                  </GoogleOAuthProvider>
                )}
                {facebookAuth && (
                  <FaccbookLoginButton facebookAppId={facebookAppId} />
                )}
              </div>

              <Link to={"/forgot-password"}>
                <h5>{t("forgotPassword")}</h5>
              </Link>
            </form>
          ) : (
            <div className="SignInForm">
              <div className="Sign_header">
                <h1>{t("signUp")}</h1>
                <h1
                  className="cursor-pointer text-primary"
                  onClick={handleSignUpClick}
                >
                  {t("signIn")}
                </h1>
              </div>
              <div className="password_con d-flex my-4">
                <input
                  type="email"
                  className="form-control"
                  aria-describedby="emailHelp"
                  placeholder={t("email")}
                  onChange={handleSignUpChange}
                  name="email"
                />
                <button className="col-3"></button>
              </div>

              <div className="password_con d-flex">
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control col-9"
                  onChange={handleSignUpChange}
                  name="password"
                  placeholder={t("password")}
                />
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    toggleShowPassword();
                  }}
                  className="col-3 p-0"
                >
                  {showPassword ? <EyeSlashFill /> : <EyeFill />}
                </button>
              </div>

              {signUpError && (
                <div className="error-message">
                  {t("emailAlreadyRegistered")}
                </div>
              )}

              <div className="Login_buttons">
                <button
                  onClick={handleSignUp}
                  type="submit"
                  className="confirm_signup col-6"
                >
                  {signUpIsLoading ? t("signingUp") : t("signUp")}
                </button>
                {googleAuth && (
                  <GoogleOAuthProvider clientId={googleClientId}>
                    <GoogleLoginButton />
                  </GoogleOAuthProvider>
                )}
                {facebookAuth && (
                  <FaccbookLoginButton facebookAppId={facebookAppId} />
                )}
              </div>

              <p className="conditions">{t("dataConfirmation")}</p>
            </div>
          )}

          <div className="links">
            <h3>Smartinb@2025</h3>
            <div>
              <Link to={""}>{t("termsConditions")} | </Link>
              <Link to={""}>{t("privacyPolicy")}</Link>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={2000} />
    </div>
  );
}

export default LoginForm;
