import React, { useEffect, useState } from "react";
import { Trash } from "react-bootstrap-icons";
import {
  useGetWishlistInfoQuery,
  useRemoveWishlistItemMutation,
} from "../../RTK/Api/WishlistApi";
import { toast } from "react-toastify";
import { useAddCartItemMutation } from "../../RTK/Api/CartApi";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import TransHook from "./../../hooks/translation/trans-hook";
import { ProductBaseUrl } from "../../api/GlobalData";

const Wishlists = ({ active }) => {
  const { data: wishlistGet, error: wishlistError } = useGetWishlistInfoQuery();
  if (wishlistError) toast.error(wishlistError);

  const cur = JSON.parse(Cookies.get("CompanyCurrency"));

  const [userData, setUserData] = useState(null);
  useEffect(() => {
    const data = Cookies.get("userData");
    if (data) {
      setUserData(JSON.parse(data));
    }

    window.scrollTo(0, 0);
  }, []);

  const [removeWishlistItem, { error: removeError }] =
    useRemoveWishlistItemMutation();
  if (removeError) toast.error(removeError);

  const { t } = TransHook();

  const handleRemoveItem = async (itemId) => {
    try {
      await removeWishlistItem(itemId)
        .unwrap()
        .then(() => {
          toast.success(t("itemRemoved"));
          const userData = JSON.parse(Cookies.get("userData"));
          userData.wishlist = userData.wishlist.filter((id) => id !== itemId);
          Cookies.set("userData", JSON.stringify(userData));
        });
    } catch (error) {
      toast.error(error);
    }
  };

  const [addCartItem, { error }] = useAddCartItemMutation();
  if (error) toast.error(error);
  const addToCart = (product) => {
    const price =
      product?.ecommercePriceMainCurrency > 0
        ? product?.ecommercePriceMainCurrency
        : parseFloat(product?.ecommercePrice);
    addCartItem({
      qr: product?.qr,
      taxPrice: price,
      quantity: 1,
      tax: {
        taxId: product.tax._id,
        taxRate: product.tax.tax,
      },
      profitRatio: product.profitRatio,
      user: userData?._id,
    })
      .unwrap()
      .then(() => toast.success(t("addedToCart")))
      .catch((error) => {
        console.error("Failed to add to cart:", error);
        if (error?.data?.message == "Cannot add more than available stock") {
          toast.error(t("productOutOfStock"));
        } else {
          toast.error(t("failedToAddToCart"));
        }
      });
  };
  const lang = localStorage.getItem("ecomLang");

  return (
    <div
      className={`tab-pane fade ${active ? "active show" : ""} ${
        lang === "ar" ? "arabic-text" : ""
      }`}
      id="my-wishlist"
      role="tabpanel"
    >
      <div className="address account-content mt-0 pt-2">
        <h4 className="title mb-3">{t("wishlist")}</h4>

        <form className="mb-2" action="#">
          <div className="wishlist-table-container">
            <table className="table table-wishlist mb-0">
              <thead>
                <tr>
                  <th className="thumbnail-col"></th>
                  <th className="product-col">{t("product")}</th>
                  {/* <th className="price-col">{t("price")}</th> */}
                  <th className="status-col">{t("stockStatus")}</th>
                  <th className="action-col">{t("actions")}</th>
                </tr>
              </thead>
              <tbody>
                {wishlistGet?.data && wishlistGet?.data?.length > 0 ? (
                  wishlistGet?.data?.map((item, i) => {
                    //NOTE: Parasut quantity
                    // const total = item.stocks.reduce(
                    //   (total, item) => total + item.productQuantity,
                    //   0
                    // );
                    const total = item?.quantity;

                    const prodName =
                      lang === "en"
                        ? item?.name
                        : lang === "tr"
                        ? item?.nameTR
                        : item?.nameAR;
                    return (
                      <tr className="product-row" key={i}>
                        <td>
                          <figure className="product-image-container">
                            <Link
                              to={`/product-details/${item?.slug || item?._id}`}
                              className="product-image"
                            >
                              <img
                                src={
                                  ProductBaseUrl + item?.imagesArray[0]?.image
                                }
                                alt={prodName?.split(" ")[0]}
                              />
                            </Link>

                            <Trash
                              className="btn-remove p-1"
                              onClick={() => handleRemoveItem(item?.id)}
                            />
                          </figure>
                        </td>
                        <td>
                          <h5 className="product-title">
                            <Link
                              to={`/product-details/${item?.slug || item?._id}`}
                            >
                              {prodName}
                            </Link>
                          </h5>
                        </td>
                        {/* <td className="price-box">
                          <h4>
                            {cur?.currencyCode}
                            {item?.ecommercePriceMainCurrency > 0
                              ? item?.ecommercePriceMainCurrency *
                                cur?.exchangeRate || 1
                              : parseFloat(
                                  item?.ecommercePrice * cur?.exchangeRate || 1
                                )}
                          </h4>
                        </td> */}
                        <td>
                          <h5 className="stock-status">
                            {total > 0 ? t("inStock") : t("unavailable")}
                          </h5>
                        </td>
                        <td className="action">
                          <Link
                            to={`/product-details/${item?.slug || item?._id}`}
                            className="btn btn-quickview mt-1 mt-md-0"
                          >
                            {t("quickView")}
                          </Link>
                          {total > 0 ? (
                            <span
                              onClick={() => addToCart(item)}
                              className="btn btn-dark btn-add-cart product-type-simple btn-shop"
                            >
                              {t("addToCart")}
                            </span>
                          ) : null}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr colspan={5}>{t("noItemsWishlist")}</tr>
                )}
              </tbody>
            </table>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Wishlists;
