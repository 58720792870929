import React, { useEffect, useState } from "react";
import {
  ArrowCounterclockwise,
  BanFill,
  CheckCircleFill,
  ClipboardCheck,
  Diagram2Fill,
  Dropbox,
  HourglassBottom,
  Truck,
  XCircleFill,
} from "react-bootstrap-icons";
import { useGetOrderInfoQuery } from "../../RTK/Api/OrderApi";
import OrderDetails from "./../../components/Order/OrderDetails";
import "./Account.css";
import TransHook from "../../hooks/translation/trans-hook";
import OrderCard from "../../components/Order/OrderCard";
import MobileOrderCard from "../../components/Order/MobileOrderCard";

const Orders = ({ active }) => {
  const { data } = useGetOrderInfoQuery();
  const { t } = TransHook();
  const [order, setOrder] = useState(null);

  const goToOrder = (item) => {
    setOrder(item);
  };

  const getStatus = (status) => {
    switch (status) {
      case "cancelled":
        return (
          <div className="d-flex w-25" style={{ alignItems: "center" }}>
            <XCircleFill color="#f06" size={20} />
            &nbsp;{" "}
            <h5 className="m-0">
              <b>{t("cancelled")}</b>
            </h5>
          </div>
        );
      case "requested":
        return (
          <div className="d-flex w-25" style={{ alignItems: "center" }}>
            <HourglassBottom color="#fa6" size={20} />
            &nbsp;{" "}
            <h5 className="m-0">
              <b>{t("requested")}</b>
            </h5>
          </div>
        );
      case "approved":
        return (
          <div className="d-flex w-25" style={{ alignItems: "center" }}>
            <ClipboardCheck color="#00fe00" size={20} />
            &nbsp;{" "}
            <h5 className="m-0">
              <b>{t("approved")}</b>
            </h5>
          </div>
        );
      case "delivered":
        return (
          <div className="d-flex w-25" style={{ alignItems: "center" }}>
            <CheckCircleFill color="#00a500 " size={20} />
            &nbsp;{" "}
            <h5 className="m-0">
              <b>{t("delivered")}</b>
            </h5>
          </div>
        );
      case "processed":
        return (
          <div className="d-flex w-25" style={{ alignItems: "center" }}>
            <Diagram2Fill color="#0af" size={20} />
            &nbsp;{" "}
            <h5 className="m-0">
              <b>{t("processed")}</b>
            </h5>
          </div>
        );
      case "not delivered":
        return (
          <div className="d-flex w-25" style={{ alignItems: "center" }}>
            <BanFill color="#f00" size={20} />
            &nbsp;{" "}
            <h5 className="m-0">
              <b>{t("notDelivered")}</b>
            </h5>
          </div>
        );
      case "shipped":
        return (
          <div className="d-flex w-25" style={{ alignItems: "center" }}>
            <Truck color="#070" size={20} />
            &nbsp;{" "}
            <h5 className="m-0">
              <b>{t("shipped")}</b>
            </h5>
          </div>
        );
      case "returned":
        return (
          <div className="d-flex w-25" style={{ alignItems: "center" }}>
            <ArrowCounterclockwise color="#f00" size={20} />
            &nbsp;{" "}
            <h5 className="m-0">
              <b>{t("returned")}</b>
            </h5>
          </div>
        );
      default:
        return (
          <div className="d-flex w-25" style={{ alignItems: "center" }}>
            <HourglassBottom color="#fa6" size={20} />
            &nbsp;{" "}
            <h5 className="m-0">
              <b>{t("ongoing")}</b>
            </h5>
          </div>
        );
    }
  };

  const determineOrderStatus = (cartItems) => {
    const statuses = cartItems.map((item) => item.orderStatus);
    if (statuses.every((status) => status === "requested")) {
      return "requested";
    }
    if (statuses.every((status) => status === "approved")) {
      return "approved";
    }
    if (statuses.every((status) => status === "processed")) {
      return "processed";
    }
    if (statuses.every((status) => status === "shipped")) {
      return "shipped";
    }
    if (statuses.every((status) => status === "delivered")) {
      return "delivered";
    }
    if (statuses.every((status) => status === "cancelled")) {
      return "cancelled";
    }
    if (statuses.every((status) => status === "not delivered")) {
      return "not delivered";
    }
    if (statuses.every((status) => status === "returned")) {
      return "returned";
    }
    return "ongoing";
  };

  useEffect(() => {
    if (data?.data && data.data.length > 0) {
      const updatedOrderStatus = data.data.map((order) =>
        determineOrderStatus(order.cartItems)
      );
    }
  }, [data]);
  const lang = localStorage.getItem("ecomLang");
  const screen = localStorage.getItem("screen") || "large";
  const [orderStatus, setOrderStatus] = useState({});

  useEffect(() => {
    if (data?.data?.length > 0) {
      const newStatuses = {};
      data.data.forEach((item) => {
        newStatuses[item._id] = determineOrderStatus(item?.cartItems);
      });
      setOrderStatus(newStatuses);
    }
  }, [data]);

  return (
    <div
      className={`tab-pane fade ${active ? "active show" : ""} ${
        lang === "ar" ? "arabic-text" : ""
      }`}
      id="my-orders"
      role="tabpanel"
    >
      <div className="order-content">
        <h3 className="account-sub-title d-none d-md-block">
          <Dropbox size={40} className="align-middle mr-3 pr-1" /> {t("orders")}
        </h3>
        <div className="order-table-container text-center">
          {order ? (
            <OrderDetails
              order={order}
              setOrder={setOrder}
              orderStatus={orderStatus[order._id]}
            />
          ) : (
            <>
              {data?.data && data?.data?.length > 0 ? (
                data?.data?.map((item, i) =>
                  screen === "large" ? (
                    <OrderCard
                      key={i}
                      goToOrder={goToOrder}
                      item={item}
                      getStatus={getStatus}
                      orderStatus={orderStatus[item._id]}
                    />
                  ) : (
                    <MobileOrderCard
                      key={i}
                      goToOrder={goToOrder}
                      item={item}
                      getStatus={getStatus}
                      orderStatus={orderStatus[item._id]}
                    />
                  )
                )
              ) : (
                <h3>{t("noOrders")}</h3>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Orders;
