import React from "react";
import { Pen, Person } from "react-bootstrap-icons";
import AccountHook from "../../hooks/Account/AccountHook";
import TransHook from "../../hooks/translation/trans-hook";

const AccountDetails = ({ active }) => {
  const {
    editing,
    accName,
    accEmail,
    accPhone,
    accIdNumber,
    newPassword,
    confirmPassword,
    toggleEditing,
    handleAccNameChange,
    handleAccEmailChange,
    handleAccPhoneChange,
    handleAccIdNumberChange,
    handleNewPasswordChange,
    handleConfirmPasswordChange,
    updatePassword,
    updateProfile,
  } = AccountHook();
  const { t } = TransHook();
  const lang = localStorage.getItem("ecomLang");

  return (
    <div
      className={`tab-pane fade ${active ? "active show" : ""} ${
        lang === "ar" ? "arabic-text" : ""
      }`}
      id="account-details"
      role="tabpanel"
    >
      <div className="d-flex" style={{ justifyContent: "space-between" }}>
        <h3 className="account-sub-title d-none d-md-block mt-0 pt-1 ml-1">
          <Person size={40} className="align-middle mr-3 pr-1" />
          &nbsp;{t("accountDetails")}
        </h3>
        <Pen
          size={30}
          className="cursor-pointer"
          onClick={() => toggleEditing()}
        />
      </div>
      <div className="account-content">
        <form action="#">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label for="acc-name">
                  {t("fullName")} <span className="required">*</span>
                </label>
                <input
                  disabled={!editing}
                  type="text"
                  className="form-control"
                  id="acc-name"
                  name="acc-name"
                  required
                  value={accName}
                  onChange={handleAccNameChange}
                />
              </div>
            </div>

            <div className="col-md-6">
              <label for="acc-email">
                {t("email")} <span className="required">*</span>
              </label>
              <input
                disabled
                type="email"
                className="form-control"
                id="acc-email"
                name="acc-email"
                required
                value={accEmail}
                onChange={handleAccEmailChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label for="acc-name">
                  {t("phoneNumber")} <span className="required">*</span>
                </label>
                <input
                  disabled={!editing}
                  type="text"
                  className="form-control"
                  id="acc-phone"
                  name="acc-phone"
                  minLength={5}
                  required
                  value={accPhone}
                  onChange={handleAccPhoneChange}
                />
              </div>
            </div>

            <div className="col-md-6">
              <label for="acc-idnumber">
                {t("idNumber")} <span className="required">*</span>
              </label>
              <input
                disabled={!editing}
                type="text"
                className="form-control"
                id="acc-idnumber"
                name="acc-idnumber"
                minLength={5}
                required
                value={accIdNumber}
                onChange={handleAccIdNumberChange}
              />
            </div>
          </div>

          <div className="change-password">
            <h3 className="text-uppercase">{t("changePassword")}</h3>
            <p style={{ color: "#aaa", marginBottom: "20px" }}>
              {t("saveButtonAppear")}
            </p>

            <div className="form-group">
              <label for="acc-password">{t("newPassTitle")}</label>
              <input
                type="password"
                className="form-control"
                id="acc-new-password"
                value={newPassword}
                onChange={handleNewPasswordChange}
                name="acc-new-password"
              />
            </div>

            <div className="form-group">
              <label for="acc-password">{t("confirmNewPassword")}</label>
              <input
                type="password"
                className="form-control"
                id="acc-confirm-password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                name="acc-confirm-password"
              />
              <ul>
                <li>{t("passwordLength")}</li>
              </ul>
            </div>
            {newPassword.length >= 8 &&
              confirmPassword.length >= 8 &&
              newPassword === confirmPassword && (
                <div className="form-footer mt-3 mb-0">
                  <span className="btn btn-dark mr-0" onClick={updatePassword}>
                    {t("updatePassword")}
                  </span>
                </div>
              )}
          </div>

          {editing && (
            <div className="form-footer mt-3 mb-0">
              <span className="btn btn-dark mr-0" onClick={updateProfile}>
                {t("save")}
              </span>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default AccountDetails;
