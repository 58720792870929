import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  useAddReviewItemMutation,
  useLazyGetUserProductReviewInfoQuery,
} from "../../RTK/Api/ReviewApi";

const ReviewHook = () => {
  const [show, setShow] = useState(false);
  const [prodId, setProdId] = useState();
  const [prodName, setProdName] = useState();
  const [rating, setRating] = useState(1);
  const [review, setReview] = useState();
  const [userInfo, setUserInfo] = useState({});

  const [trigger, { data, isLoading, error }] =
    useLazyGetUserProductReviewInfoQuery();

  const [addReview] = useAddReviewItemMutation();

  const handleClose = () => setShow(false);
  const handleReviewChange = (event) => setReview(event.target.value);
  const handleShow = (id, name) => {
    setProdId(id);
    setProdName(name);
    setShow(true);
    trigger({ productId: id });
  };

  useEffect(() => {
    setRating(data?.data?.rating);
    setReview(data?.data?.review);
  }, [data?.data]);

  useEffect(() => {
    const userDataString = Cookies.get("userData");
    const userData = JSON.parse(userDataString);
    setUserInfo(userData);
  }, []);

  const formData = {
    review,
    rating,
    product: prodId,
    customar: userInfo._id,
  };

  const handleSubmit = () => {
    addReview(formData);
    handleClose();
  };

  return {
    rating,
    review,
    data,
    isLoading,
    error,
    show,
    prodName,
    setRating,
    handleClose,
    handleReviewChange,
    handleShow,
    handleSubmit,
  };
};

export default ReviewHook;
