import { useTranslation } from "react-i18next";

const TransHook = () => {
  const { t, i18n } = useTranslation();
  const lang = localStorage.getItem("ecomLang");

  if (!lang) {
    window.location.reload();
    localStorage.setItem("ecomLang", "tr");
  }

  const changeEn = () => {
    i18n.changeLanguage("en");
    localStorage.setItem("ecomLang", "en");
  };

  const changeAr = () => {
    i18n.changeLanguage("ar");
    localStorage.setItem("ecomLang", "ar");
  };

  const changeTr = () => {
    i18n.changeLanguage("tr");
    localStorage.setItem("ecomLang", "tr");
  };

  return { changeAr, changeEn, changeTr, t };
};

export default TransHook;
