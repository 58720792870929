import React from "react";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import Loader from "./../../Loaders/Loader";
import Footer from "../../utility/Footer/Footer";
import { ToastContainer } from "react-toastify";
import Navbar from "../../utility/navbar/navbar";
import { Button, Modal } from "react-bootstrap";
import "../CSS/Reviews.css";
import TransHook from "../../../hooks/translation/trans-hook";
import ProductQuestionsHook from "../../../hooks/product/questions/getProductQuestionsHook";
import AllReviewsHook from "./../../../hooks/Review/AllReviewsHook";
import GetOneProductHook from "../../../hooks/product/getOneProductHook";

const AllQuestions = () => {
  const { id } = useParams();
  const { t } = TransHook();
  const lang = localStorage.getItem("ecomLang");

  const companyData = JSON.parse(Cookies.get("CompanyData"));
  const {
    questions,
    isLoading,
    addQuestionLoading,
    open,
    question,
    handleAddQuestion,
    setQuestion,
    setOpen,
  } = ProductQuestionsHook(id);

  const {
    isLoading: isLoadingReview,
    rateCount,
    overallRatings,
    renderStars,
  } = AllReviewsHook(id);

  const { Pname, data, isLoading: isLoadingProduct } = GetOneProductHook(id);

  if (isLoading || isLoadingReview || isLoadingProduct || addQuestionLoading) {
    return (
      <>
        <Navbar />
        <Loader />
        <Footer />
      </>
    );
  } else {
    return (
      <>
        <Navbar />
        <div style={{ marginTop: "240px" }} className="container d-flex">
          <div className="col-12 col-lg-3">
            <div className="position-fixed">
              <h2 className="pb-3 ps-5">{t("overallRatings")}</h2>
              <div className="overall-ratings">
                <div className="">
                  <img
                    style={{ width: "128px" }}
                    className="mx-auto rounded-2"
                    src={data?.imagesArray?.filter((item) => item.isCover)}
                    alt={Pname?.substring(0, 30) + `...`}
                  />
                  <h3>{Pname?.substring(0, 30) + `...`}</h3>
                </div>
                <div className="text-center">
                  <p className="heading">
                    <b>{overallRatings?.toFixed(2)}</b>
                  </p>
                  {renderStars()}
                  <p className="mt-3">
                    {rateCount} {t("reviewsCounted")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Questions */}
          <div className="col-12 col-lg-9">
            <div className="d-flex product-action justify-content-between">
              <h2 className="pb-3">{t("productQuestions")}</h2>
              <p
                className="btn btn-dark addToCartBtn"
                onClick={() => setOpen(true)}
              >
                {t("askQuestion")}
              </p>
            </div>
            <div className="mt-2">
              {questions?.data
                ?.filter((question) => question.approved)
                .reduce((rows, question, index) => {
                  if (index % 2 === 0) rows.push([]);
                  rows[rows.length - 1].push(question);
                  return rows;
                }, [])
                .map((row, rowIndex) => (
                  <div
                    key={rowIndex}
                    className="d-flex justify-content-between"
                  >
                    {row.map((question, index) => (
                      <div
                        dir={lang === "ar" ? "rtl" : "ltr"}
                        className="col-6 m-4 border border-1 rounded-4 p-0"
                        key={index}
                      >
                        <h3 className="px-3 pt-2">{question?.question}</h3>
                        <div className="px-3 pb-2 d-flex">
                          <p style={{ fontSize: "14px" }} className="m-0 p-0">
                            {question?.customar?.name
                              ?.split(" ")
                              .map(
                                (word) => word.charAt(0).toUpperCase() + "***"
                              )
                              .join(" ")}
                          </p>
                          &nbsp;-&nbsp;
                          <p style={{ fontSize: "14px" }} className="m-0 p-0">
                            {question?.updateTime}
                          </p>
                        </div>
                        <div className="rounded-2 bg-gray p-2">
                          <div className="d-flex align-items-center mt-2 mx-2">
                            <img
                              src={companyData?.companyLogo}
                              alt={companyData?.companyName}
                              width={64}
                              height={64}
                            />
                            <p
                              style={{ fontSize: "14px" }}
                              className="mx-2 my-0"
                            >
                              {companyData?.companyName}
                            </p>
                          </div>
                          <p style={{ fontSize: "14px" }} className="mx-2 my-2">
                            {question?.answer && question.answer}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <Footer />
        <ToastContainer autoClose={2000} />
        <Modal show={open} onHide={() => setOpen(false)}>
          <Modal.Body>
            <h2 className="my-3">{t("askQuestion")}</h2>
            <input
              type="text"
              className="form-control"
              value={question}
              style={{ fontSize: "16px", height: "30px" }}
              onChange={(e) => setQuestion(e.target.value)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setOpen(false)}>
              <h4 className="my-0 py-2">{t("cancel")}</h4>
            </Button>
            <Button variant="primary" onClick={() => handleAddQuestion()}>
              <h4 className="my-0 py-2">{t("send")}</h4>
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
};

export default AllQuestions;
