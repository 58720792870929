import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../utility/Slider/Slider.css";
import { useGetParentCategoryInfoQuery } from "../../RTK/Api/CategoryApi";
import { toast } from "react-toastify";
import TransHook from "../../hooks/translation/trans-hook";

const HomeCategories = () => {
  const navigate = useNavigate();
  const handleButtonClick = (id) => {
    navigate(`/products/${id}/category`);
  };

  const [ParentCategories, setParentCategories] = useState([]);
  const { data: category, error: categoryError } =
    useGetParentCategoryInfoQuery();
  if (categoryError) toast.error(categoryError);
  useEffect(() => {
    setParentCategories(
      category?.data?.filter(
        (category) =>
          !category.parentCategory &&
          category.ecommerceVisible &&
          category.ecommerceHomeVisible
      )
    );
  }, [category]);
  const screen = localStorage.getItem("screen") || "large";
  const { t } = TransHook();
  const lang = localStorage.getItem("ecomLang");

  if (ParentCategories?.length > 0) {
    return (
      <div
        className={
          screen === "large" ? "Home-Category" : "Home-Category-mobile"
        }
      >
        <h6
          className={`section-title ls-n-10 pb-3 m-b-4  ${
            lang === "ar" ? "arabic-text" : ""
          }`}
        >
          {t("discoverOurCategories")}
        </h6>
        {screen === "large" ? (
          <div className="Categories d-flex">
            {ParentCategories && (
              <div className="main_cat col-5">
                <div className="Cat_img">
                  <img
                    src={
                      ParentCategories[0]?.image
                        ? ParentCategories[0]?.image
                        : "https://i1.wp.com/gelatologia.com/wp-content/uploads/2020/07/placeholder.png"
                    }
                    alt="CategoryImg"
                    onClick={() => handleButtonClick(ParentCategories[0]?._id)}
                  />
                </div>
                <div className="Cat_info">
                  <h6>
                    {lang === "ar"
                      ? ParentCategories[0]?.nameAR
                      : lang === "tr"
                      ? ParentCategories[0]?.nameTR
                      : ParentCategories[0]?.name}
                  </h6>
                </div>
              </div>
            )}

            <div className="sub_cats col-7 ">
              {ParentCategories?.slice(1, 7).map((item, index) => (
                <div key={index} className=" sub_cat d-flex">
                  <img
                    className="horizontalImg border-10"
                    src={
                      item?.image
                        ? item?.image
                        : "https://i1.wp.com/gelatologia.com/wp-content/uploads/2020/07/placeholder.png"
                    }
                    alt="CategoryImg"
                    onClick={() => handleButtonClick(item?._id)}
                  />
                  <h4>
                    {lang == "ar"
                      ? item.nameAR
                      : lang == "tr"
                      ? item.nameTR
                      : item.name}
                  </h4>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="Categories">
            {ParentCategories && (
              <div className="main_cat col-12">
                <div className="Cat_img">
                  <img
                    src={
                      ParentCategories[0]?.image
                        ? ParentCategories[0]?.image
                        : "https://i1.wp.com/gelatologia.com/wp-content/uploads/2020/07/placeholder.png"
                    }
                    alt="CategoryImg"
                    onClick={() => handleButtonClick(ParentCategories[0]?._id)}
                  />
                </div>
                <div className="Cat_info text-center">
                  <h4>{ParentCategories[0]?.name}</h4>
                </div>
              </div>
            )}

            <div className="sub_cats col-12 d-flex flex-wrap text-center">
              {ParentCategories?.slice(1, 5).map((item, index) => (
                <div key={index} className="sub_cat col-6 mt-3">
                  <img
                    className="horizontalImg border-10 mx-auto my-4"
                    src={
                      item?.image
                        ? item?.image
                        : "https://i1.wp.com/gelatologia.com/wp-content/uploads/2020/07/placeholder.png"
                    }
                    alt="CategoryImg"
                    style={{
                      objectFit: `cover`,
                      width: "128px",
                      height: "128px",
                    }}
                    onClick={() => handleButtonClick(item?._id)}
                  />
                  <h4>{item?.name}</h4>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
};

export default HomeCategories;
